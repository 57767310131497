import { useState, useEffect, useRef } from "react";
import { Api, Endpoint } from "../../api";
import SelectList from "../../components/SelectList";
import withDashboardLayout from "../../Layouts/AdminPanel";
import helpers from "../../helpers/general";
import CustomLineChart from "../../components/charts/CustomLineChart";
import CustomBarChart from "../../components/charts/CustomBarChart";
import styles from "../../components/Table/Table.module.scss";
import { Table as BootstrapTable } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";
import CustomPieChart from "../../components/charts/CustomPieChart";

const timeOptions = [
  { value: "7", label: "Last 7 days" },
  { value: "30", label: "Last 30 days" },
  { value: "90", label: "Last 90 days" },
  { ...helpers.generateTimeOptions.thisWeek() },
  { ...helpers.generateTimeOptions.thisMonth() },
  { ...helpers.generateTimeOptions.thisQuarter() },
  { ...helpers.generateTimeOptions.thisYear() },
];

function Analytics() {
  const [data, setData] = useState();
  // const [activeUsers, setActiveUsers] = useState();
  const [time, setTime] = useState({ value: "7", label: "Last 7 days" });
  const [titles, setTitles] = useState(null);
  const wrapper = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTitles({
      activeUsers: `Active users / Sessions (${time?.value} Day${
        time?.value !== 1 ? "s" : ""
      })`,
      bounceRate: `% of customers who viewed only one page (${time?.value} Day${
        time?.value !== 1 ? "s" : ""
      })`,
      screenPageViewsPerUser: `Number of items viewed per user/customer (${
        time?.value
      } Day${time?.value !== 1 ? "s" : ""})`,
      // averageSessionDuration: `Average user/customer time spent (seconds) (${
      userEngagementDuration: `Average user/customer time spent (minutes) (${
        time?.value
      } Day${time?.value !== 1 ? "s" : ""})`,
      sessionByHour: `Number of page views per hour (${time?.value} Day${
        time?.value !== 1 ? "s" : ""
      })`,
      sessions: `Number of page views in (${time?.value} Day${
        time?.value !== 1 ? "s" : ""
      })`,
      screenPageViewsPerSession: `Average page views per session (${
        time?.value
      } Day${time?.value !== 1 ? "s" : ""})`,
      sessionsPerUser: "How many times same users engage",
      sessionsBySource: `How users/customers found foodi (${time?.value} Day${
        time?.value !== 1 ? "s" : ""
      })`,
    });
  }, [time]);

  useEffect(() => {
    (async () => {
      try {
        const response = await Api.call(Endpoint.gapi.getData, {
          query: { time: time.value },
        });

        console.log({ response });

        if (response?.statusCode === 200) {
          setLoading(false);
          setData({
            ...response?.message,
          });
        }
      } catch (error) {
        console.log({ error });
      }
    })();
  }, [time]);

  if (loading) {
    return <LoadingSpinner fullScreen />;
  }

  return (
    <div ref={wrapper} style={{ marginBottom: 40 }}>
      <div style={{ maxWidth: 400, marginBottom: 40 }}>
        <SelectList
          placeholder="Select a time option..."
          value={time}
          onChange={setTime}
          options={timeOptions}
          isMulti={false}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          flexWrap: "wrap",
          width: "100%",
          marginBottom: 40,
        }}
      >
        {data?.activeUsers?.[0]?.data?.length !== 0 && (
          <section className="chart-wrapper">
            <h5>{titles?.activeUsers}</h5>
            <CustomLineChart data={data?.activeUsers} />
          </section>
        )}

        {data?.bounceRate?.length !== 0 && (
          <section className="chart-wrapper">
            <h5>{titles?.bounceRate}</h5>
            <CustomBarChart data={data?.bounceRate} />
          </section>
        )}

        {/* {data?.userEngagementDuration?.[0]?.data?.length !== 0 && (
          <section className="chart-wrapper">
            <h5>{titles?.userEngagementDuration}</h5>
            <CustomLineChart data={data?.userEngagementDuration} />
          </section>
        )} */}

        {data?.screenPageViewsPerSession?.length !== 0 && (
          <section className="chart-wrapper">
            <h5>{titles?.screenPageViewsPerSession}</h5>
            <CustomBarChart data={data?.screenPageViewsPerSession} />
          </section>
        )}

        {/* {data?.sessions?.[0]?.data?.length !== 0 && (
          <section className="chart-wrapper">
            <h5>{titles?.sessions}</h5>
            <CustomLineChart data={data?.sessions} />
          </section>
        )} */}

        {data?.sessionsPerUser?.[0]?.data?.length !== 0 && (
          <section className="chart-wrapper">
            <h5>{titles?.sessionsPerUser}</h5>
            <CustomLineChart data={data?.sessionsPerUser} />
          </section>
        )}

        {data?.screenPageViewsPerUser?.[0]?.data?.length !== 0 && (
          <section className="chart-wrapper">
            <h5>{titles?.screenPageViewsPerUser}</h5>
            <CustomLineChart data={data?.screenPageViewsPerUser} />
          </section>
        )}

        {data?.sources?.length !== 0 && (
          <section className="chart-wrapper">
            <h5>How users found your menu</h5>
            <CustomPieChart data={data?.sources} />
          </section>
        )}

        {data?.mostVisitedMenus?.length !== 0 && (
          <section className="chart-wrapper">
            <h5>
              Most visited Menus ({time?.value} Day
              {time?.value !== 1 ? "s" : ""})
            </h5>
            <div className={styles.customTableWrapper}>
              <BootstrapTable className={styles.customTable}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Menu Title</th>
                    <th>Menu Id</th>
                    <th style={{ textAlign: "right" }}># Visits</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.mostVisitedMenus?.map((menu, index) => {
                    const regex =
                      /platform\.foodi-menus\.com\/menu\/([a-f0-9]{24})/;

                    const match = menu?.path?.match(regex);
                    if (match) {
                      const path = match[1];
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <a href={`https://${menu?.path}`} target="_blank">
                              {menu?.title}
                            </a>
                          </td>
                          <td>
                            <code style={{ color: "#000" }}>{path}</code>
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "bold",
                              fontFamily: "monospace",
                            }}
                          >
                            {menu?.value}
                          </td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  })}
                </tbody>
              </BootstrapTable>
            </div>
          </section>
        )}

        {data?.mostVisitedMenuItems?.length !== 0 && (
          <section className="chart-wrapper">
            <h5>
              Most visited menu item ({time?.value} Day
              {time?.value !== 1 ? "s" : ""})
            </h5>

            <div className={styles.customTableWrapper}>
              <BootstrapTable className={styles.customTable}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Menu Title</th>
                    <th>Item Id</th>
                    <th style={{ textAlign: "right" }}># Visits</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.mostVisitedMenuItems?.map((dish, index) => {
                    const regex = /dish\/([a-f0-9]{24})/;

                    const match = dish?.path?.match(regex);
                    if (match) {
                      const path = match?.[1];
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <a href={`https://${dish?.path}`} target="_blank">
                              {dish?.title}
                            </a>
                          </td>
                          <td>
                            <code style={{ color: "#000" }}>{path}</code>
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "bold",
                              fontFamily: "monospace",
                            }}
                          >
                            {dish?.value}
                          </td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  })}
                </tbody>
              </BootstrapTable>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

export default withDashboardLayout(Analytics);
